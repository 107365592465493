import { FilterInfo } from '../entity/FilterInfo'
import { ProductSort } from '../entity/ProductSort'
import {credentialManager} from "../repo/CredentialManager";
import { Offer } from './dto/OfferDto'
import {CreatePaymentInfo, ProductOrder, PromoCode} from './dto/PaymentDto'
import {Platform, Product, ProductFull} from './dto/ProductDto'
import { ProductReferralLinkResponse } from './dto/ProductReferralLinkResponse'
import { ReferralLink } from './dto/ReferralLink'
import { TagDto } from './dto/TagDto'
import {
	IPersonalDocumentResponse,
	IPersonalInfoResponse,
	IUpdatePersonalDocumentRequest,
	IPersonalInfo,
	User,
	IAgreementsResponse,
	IPayoutHistoryResponse,
	IUpdateUserRequest,
	IPersonalRequisitesResponse,
	IBank,
	ICreatePersonalRequisiteRequest,
	IUnsignedPayoutActsResponse,
	IPayoutActResponse,
	ILegalStatusRequest,
	CheckCompanyResponse,
} from './dto/UserDto'
import {StatisticOffer} from "./dto/StatisticOffer";
import {ClickInfo} from "./dto/ClickInfo";
import {TopSeller} from "./dto/TopSeller";
import {RegisterActivateResponse} from "./dto/RegisterActivateResponse";

export const uriBase = process.env.NODE_ENV === 'production'
    ? 'https://appsell.me'
    : 'https://demo.appsell.me'

export const network = {
    login: async (email: string, password: string) => api.post<LoginResponse>(
        '/api/auth/login',
        {
            email: email,
            password: password
        },
        null
    ),

	checkCompanyId: async (companyId: string) => api.get<CheckCompanyResponse>(`/api/companies/${companyId}/existence`, null),

	checkEmployeeNumber: async (companyId: string, employeeNumber: string) => api.get<Response>(`/api/companies/${companyId}/employees/${employeeNumber}/existence`, null),

    getUser: async () => api.get<User>(`/api/users/current`, null),

    updateUser: async (user: IUpdateUserRequest) => api.put(`/api/users/current`, user, null),

    getPersonalInfo: async () => api.get<IPersonalInfoResponse>(`/api/users/current/personal-info`, null),

    updatePersonalInfo: async (personalInfo: IPersonalInfo) => api.put(`/api/users/current/personal-name`, personalInfo, null),

    getPersonalDocument: async () => api.get<IPersonalDocumentResponse>(`/api/users/current/personal-document`, null),

    updatePersonalDocument: async (personalDocument: IUpdatePersonalDocumentRequest) => api.post(`/api/users/current/personal-document`, personalDocument, null),

    uploadPersonalDocumentFile: async (file: File) => api.upload<Response>(`/api/users/current/personal-document/file`, {file}),

    verifyPersonalDocument: async () => api.post<Response>(`/api/users/current/personal-document/verify`, null, null),

	getLegalStatus: async () => api.get<Response>(`/api/users/current/legal-status`, null),

	updateLegalStatus: async (legalStatus: ILegalStatusRequest) => api.post<Response>(`/api/users/current/legal-status`, legalStatus, null),

    getAgreements: async () => api.get<IAgreementsResponse>(`/api/users/current/agreements`, null),

    requestAgreementSigningCode: async () => api.post<Response>(`/api/users/current/agreements`, null, null),

    signAgreement: async (code: string) => api.post<Response>(`/api/users/current/agreements/sign`, {code}, null),

    getPayoutHistory: async () => api.get<IPayoutHistoryResponse>(`/api/users/current/payout/history`, null),

    createPayout: async () => api.post<IPayoutActResponse>(`/api/users/current/acts`, null, null),

    getPayoutAct: async (id: string) => api.get<IPayoutActResponse>(`/api/users/current/acts/${id}`, null),

	getUnsignedPayoutAct: async () => api.get<IUnsignedPayoutActsResponse>(`/api/users/current/acts`, null),

    requestActSigningCode: async (id: string) => api.post<Response>(`/api/users/current/acts/${id}/request-sign-code`, null, null),

    signPayoutAct: async (id: string, code: string) => api.post<Response>(`/api/users/current/acts/${id}/sign`, {code}, null),

    getPersonalRequisites: async () => api.get<IPersonalRequisitesResponse>(`/api/users/current/personal-requisites`, null),

    createPersonalRequisite: async (personalRequisite: ICreatePersonalRequisiteRequest) => api.post<Response>(`/api/users/current/personal-requisites`, personalRequisite, null),

    activatePersonalRequisite: async (id: string) => api.post<Response>(`/api/users/current/personal-requisites/${id}/activate`, null, null),

    removePersonalRequisite: async (id: string) => api.delete<Response>(`/api/users/current/personal-requisites/${id}`, null, null),

    getNspkBanks: async () => api.get<IBank[]>(`/api/nspk/banks`, null),

    getOffers: async (count: number, offset: number, tag: string | null, query: string | null, abortSignal: AbortSignal | undefined = undefined) => {
        const params = new Map<string, string>()
        params.set("limit", `${count}`)
        params.set("offset", `${offset}`)
        if (tag) {
            params.set("tags", `${tag}`)
        }
        if (query) {
            params.set("query", `${query}`)
        }
        return api.get<Offer[]>(
            "/api/employees/offers",
            params
        )
    },

    getProducts: async (count: number, offset: number, tag: string | null, query: string | null, filterInfo: FilterInfo, sort: ProductSort, abortSignal: AbortSignal | undefined = undefined) => {
        const params = new Map<string, string>()
        params.set("limit", `${count}`)
        params.set("offset", `${offset}`)
        if (tag) {
            params.set("tags", `${tag}`)
        }
        if (query) {
            params.set("query", `${query}`)
        }
        if (filterInfo.platforms?.length) {
            params.set("filter[platforms]", filterInfo.platforms?.join(','))
        }
        if (filterInfo.publishers?.length) {
            params.set("filter[publishers]", filterInfo.publishers?.join(','))
        }
        if (filterInfo.activationPlatforms?.length) {
            params.set("filter[activation_platforms]", filterInfo.activationPlatforms?.join(','))
        }
        if (filterInfo.releaseYears?.length) {
            params.set("filter[release_years]", filterInfo.releaseYears?.join(','))
        }
        if (filterInfo.isPromo === true) {
            params.set("filter[is_promo]", "true")
        }
        if (sort === ProductSort.PRICE_ASC) {
            params.set("sort[price]", "asc")
        } else if (sort === ProductSort.PRICE_DESC) {
            params.set("sort[price]", "desc")
        }
        return api.get<Product[]>(
            "/api/employees/products",
            params
        )
    },

    getOffer: async (offerId: string) => {
        return api.get<Offer[]>(
            `/api/employees/offers/${offerId}`,
            null
        )
    },

    getOfferGroup: async (offerGroupId: string) => {
        return api.get<Offer[]>(
            `/api/employees/offers/${offerGroupId}/children`,
            null
        )
    },

    getProductReferralLink: async (productId: string) => {
        return api.post<ProductReferralLinkResponse>(
            `/api/employees/products/${productId}/referral-links`,
            null,
            null
        )
    },

    getProduct: async (productId: string) => {
        return api.get<ProductFull>(
            `/api/employees/products/${productId}`,
            null
        )
    },

    createProductOrder: async (productId: string) => {
        return api.post<ProductOrder>(
            `/api/payment-orders`,
            {
                offer_id: productId
            },
            null
        )
    },

    getProductOrderStatus: async(orderId: string, abortSignal: AbortSignal | undefined = undefined) => {
        return api.get<ProductOrder>(
            `/api/payment-orders/${orderId}`,
            null,
            abortSignal
        )
    },

    getTags: async () => api.get<TagDto[]>(
        "/api/employees/offers/tags",
        null
    ),

    getProductTags: async () => api.get<TagDto[]>(
        "/api/employees/products/tags",
        null
    ),

    getReferralLink: async (offerId: string) => api.post<ReferralLink>(
        `/api/employees/offers/${offerId}/referral-links`,
        null,
        null
    ),

    getFollowInfo: async (linkId: string) => api.get<FollowInfo>(
        `/api/referral-links/${linkId}/follow-info`,
        null,
    ),

    sendKeyAgain: async (orderId: string) => api.get(
        `/api/payment-orders/${orderId}/receipt`,
        null
    ),

    requestPromoCode: async (offerId: string, executionId: string, token: string | null = null) => api.post<PromoCode>(
        `/api/employees/offers/${offerId}/promo-codes/lock`,
        {
            execution_id: executionId,
            token
        },
        null
    ),

    markPromocodeAsShown: async (promocodeId: string) => api.post(
        `/api/employees/offers/promo-codes/${promocodeId}/mark-as-shown`,
        null,
        null
    ),

    getPaymentStatus: async (orderId: string, abortSignal: AbortSignal | undefined = undefined) => api.get<CreatePaymentInfo>(
        `/api/payment-orders/${orderId}`,
        null,
        abortSignal
    ),

    createPayment: async (offerId: string, executionId: string | null) => api.post<CreatePaymentInfo>(
        `/api/payment-orders`,
        {
            offer_id: offerId,
            execution_id: executionId
        },
        null
    ),

    getProductPlatforms: async () => api.get<Platform[]>(
        `/api/employees/products/platforms`,
        null
    ),

    getProductYears: async () => api.get<Platform[]>(
        `/api/employees/products/release-years`,
        null
    ),

    getProductPublishers: async () => api.get<Platform[]>(
        `/api/employees/products/publishers`,
        null
    ),

    getProductPlatformsActivation: async () => api.get<Platform[]>(
        `/api/employees/products/activation-platforms`,
        null
    ),

    getStatistic: async  (start: string, end: string, abortSignal: AbortSignal | undefined) => {
        const params = new Map<string, string>()
        params.set('start_date', start)
        params.set('end_date', end)
        return api.get<StatisticOffer[]>(
            '/api/employees/offers/statistics-v2',
            params,
            abortSignal
        )
    },

    getClicksStatistic: async (offerId: string, start: string, end: string) => {
        const params = new Map<string, string>()
        params.set('start_date', start)
        params.set('end_date', end)
        return api.get<ClickInfo[]>(
            `/api/employees/offers/${offerId}/clicks/statistics/`,
            params,
        )
    },

    getTopSellers: async (yearNumber: number, monthNumber: number)=> {
        const params = new Map<string, string>()
        params.set('year', `${yearNumber}`)
        params.set('month', `${monthNumber}`)
        return api.get<TopSeller[]>(
            `/api/employees/rating/monthly`,
            params,
        )
    },

    register: async (company_id: string, email: string, password: string)=> api.post<Response>(
        `/api/registration/employees`,
        {
            company_id,
            email,
            password
        },
        null
    ),

    activate: async (email: string, code: string, employee_number?: string)=> api.post<RegisterActivateResponse>(
        `/api/registration/employees/activate`,
        {
            email,
            code,
            employee_number
        },
        null
    ),

    reset: async (email: string, new_password: string, code: string) => api.put<Response>(
        `/api/users/password`,
        {
            email,
            new_password,
            code
        },
        null
    ),

    resetGetCode: async (email: String) => api.post<Response>(
        `/api/users/password/recovery-codes`,
        {
            email
        },
        null
    ),

    changePassword: async (password: string, new_password: string) => api.put<LoginResponse>(
        `/api/users/current/password`,
        {
            password,
            new_password
        },
        null
    ),

    deleteAccount: async () => api.delete<Response>(`/api/users/current`, null, null),
}

const api = {
    get: async <TResponse>(url: string, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(`${uriBase}${url}`, 'GET', params, null, abortSignal),

    post: async <TResponse>(url: string, body: object | null, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(
            `${uriBase}${url}`, 'POST', params, body, abortSignal),

    put: async <TResponse>(url: string, body: object | null, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(
            `${uriBase}${url}`, 'PUT', params, body, abortSignal),

    delete: async <TResponse>(url: string, body: object | null, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(
            `${uriBase}${url}`, 'DELETE', params, body, abortSignal),

    upload: async <TResponse>(url: string, params: object | null) => upload<TResponse>(`${uriBase}${url}`, params),
}

async function upload<TResponse>(
    url: string,
    params: Record<string, any> | null,
): Promise<TResponse> {
    const body: FormData = new FormData();
    if (params) for (let key in params) body.append(key, params[key]);

    const token = credentialManager.getToken()
    const response = await fetch(
        `${url}`,
        {
            method: `POST`,
            headers: {
                ...(token !== null && {Authorization: `Bearer ${token}`})
            },
            body
        }
    ).then((response) => {
        if (!response.ok) {
            if ((response.status === 401 ||response.status === 403) && credentialManager.isLogged()) {
	              credentialManager.logOut();
								return;
            }
            return response.json().then((baseResp) => {
                baseResp.status = response.status;
                throw baseResp;
            })
        } else {
            return response.json()
                .catch((e) => {
                    console.log(e)
                    return response
                })
        }
    })
    return await response;
}

async function request<TResponse>(
    url: string,
    method: string,
    params: Map<string, string> | null,
    body: object | null,
    abortSignal: AbortSignal | undefined
): Promise<TResponse> {
    /*let query = ``
    if (params !== null) {
        query = '?'
        query += Array.from(params.entries())
            .map((value) => encodeURIComponent(value[0]) + `=` + encodeURIComponent(value[1]))
            .join(`&`);
    }
    const token = credentialManager.getToken();
    const res = await fetch(
        `${url}${query}`,
        {
            method: method,
            headers: {
                Accept: 'application/json',
                ...(!!body && {'Content-Type': 'application/json'}),
                ...(!!token && {Authorization: `Bearer ${token}`})
            },
            signal: abortSignal,
            ...(!!body && {body: JSON.stringify(body)})
        }
    );

    if ([401, 403].includes(res.status)) {
        eventBus.post(LogoutEvent);
    }

    const response = await res.json();
    return {
        status: res.status,
        ...(response ?? {})
    };*/

    try {
        let queries = ''
        if (params !== null) {
            queries = '?'
            queries += Array.from(params.entries())
                .map((value) => encodeURIComponent(value[0]) + '=' + encodeURIComponent(value[1]))
                .join('&')
        }
        const token = credentialManager.getToken()
        const response = await fetch(
            `${url}${queries}`,
            {
                method: method,
                headers: {
                    Accept: 'application/json',
                    ...(body !== null && {'Content-Type': 'application/json'}),
                    ...(token !== null && {Authorization: `Bearer ${token}`})
                },
                signal: abortSignal,
                ...(body !== null && {body: JSON.stringify(body)})
            }
        ).then((response) => {
            if (!response.ok) {
                if ((response.status === 401 || response.status === 403) && credentialManager.isLogged()) {
					credentialManager.logOut();
					return;
                }
                return response.json().then((baseResp) => {
                    baseResp.status = response.status;
                    throw baseResp;
                })
            } else {
                return response.json().then((baseResp) => {
                    if (!baseResp.status) baseResp.status = response.status;
                    return baseResp;
                })
                    .catch((e) => {
                        console.log(e)
                        return response;
                    });
            }
        })
        // @ts-ignore
        return await response;
    } catch(e) {
        console.log(e);
        // @ts-ignore
        return e;
    }
}
