import React, {Suspense} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {observer} from "mobx-react";
import {ToastContainer} from "react-toastify";
import "./variables.css";
import "react-toastify/dist/ReactToastify.css";

import routes from "./routes";

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    }
});

interface IRoute {
    path: string
    element: JSX.Element
    routes?: IRoute[]
}

const drawRoutes = (routes: IRoute[], parentPath: string = ``) => (
    <>
        {routes.map((route: IRoute) => (
            <Route key={`${parentPath}${route.path}`} path={`${parentPath}${route.path}`} element={route.element}>
                {!!route.routes && drawRoutes(route.routes, route.path)}
            </Route>
        ))}
    </>
);

const App = () => {
    /*const viewModel = useMemo(() => new _AppViewModel(), [])
    const mainViewModel = useMemo(() => new MainViewModel(), [])
    const catalogViewModel = useMemo(() => new _CatalogViewModel(), [])
    const offersViewModel = useMemo(() => new OffersViewModel(), [])
    const productsViewModel = useMemo(() => new ProductsViewModel(), [])
    const profileStore = useMemo(() => new profileStore(), [])
    const statisticViewModel = useMemo(() => new StatisticViewModel(), [])

    useEffect(
        () => reaction(() => viewModel.event,
            event => {
                if (event === AppEvent.Login || event === AppEvent.Logout) {
                    window.location.replace(window.location.origin)
                }
            }),
        []
    ) TODO вот реакция на логаут */

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Suspense fallback={null}>
                    <Routes>{drawRoutes(routes)}</Routes>
                </Suspense>
            </BrowserRouter>
            <ToastContainer />
        </QueryClientProvider>
    );
}

export {queryClient};
export default observer(App);
